<template>
  <div class="form-group d-block">
    <div v-if="showLabel" class="label">
      {{ label ? label : 'Birthday' }}
    </div>
    <div class="row">
      <div class="col-6">
        <select v-model="month" name="month" class="form-control" >
          <option value="">Month</option>
          <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
        </select>
        <i class="ion ion-ios-arrow-down icon-right input-icon"></i>
      </div>
      <div class="col-6">
        <select v-model="day" name="day" class="form-control" >
          <option value="">Day</option>
          <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
        </select>
        <i class="ion ion-ios-arrow-down icon-right input-icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  props: {
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      tempMonth: "",
      tempDay: "",
    }
  },

  computed: {
    month: {
      get() {
        if (!this.value) return this.tempMonth;
        return this.value.split('-')[1]
      },
      set(newValue) {
        this.tempMonth = newValue
        let year = this.value ? moment(this.value).format('YYYY') : null;
        if (!year) year = 2022
        this.$emit('input', `${year}-${newValue}-${this.day}`)
      },
    },
    day: {
      get() {
        if (!this.value) return this.tempDay;
        return this.value.split('-')[2]
      },
      set(newValue) {
        this.tempDay = newValue
        let year = this.value ? moment(this.value).format('YYYY') : null;
        if (!year) year = 2022
        this.$emit('input', `${year}-${this.month}-${newValue}`)
      },
    
    },
  },
}
</script>

<style lang="scss" scoped>
  select {
    &.form-control {
      border: 1px solid #e7e7e7;
      border-radius: 0 !important;
      padding: 0 10px;
      height: 35px !important;
      background-image: none !important;
      display: inline-block;

      & + .ion.input-icon:after {
        transform: translate(-19px, 2px);
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    option {
      text-align: left;
    }
  }

  .input-icon {
    top: 0.55rem !important;
    right: 1.5rem;
  }
  div.label {
    font-size: .6rem;
    display: block;
    color: #333;
    text-transform: uppercase;
    clear: both;
    padding-bottom: 5px;
    font-weight: 600;
  }
</style>
