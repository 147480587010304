import { render, staticRenderFns } from "./BirthdayInput.vue?vue&type=template&id=6a60a647&scoped=true&"
import script from "./BirthdayInput.vue?vue&type=script&lang=js&"
export * from "./BirthdayInput.vue?vue&type=script&lang=js&"
import style0 from "./BirthdayInput.vue?vue&type=style&index=0&id=6a60a647&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a60a647",
  null
  
)

export default component.exports